<template>
  <b-overlay :show="loading" class="col-12 col-lg-8 g c">
    <div class="card">
      <div class="card-body">
        <p>اختر الاجهزة المراد الارسال منها</p>
        <div class="row">
          <div
            class="col-12 alert alert-warning text-danger g"
            v-if="devices.length == 0"
          >
            ليس لديك اي اجهزة في حسابك.
          </div>
          <template v-for="device in devices">
            <div
              class="col-12 col-lg-6"
              :key="device._id"
              v-if="device.status == 1"
            >
              <div class="card card-body border">
                <b-form-checkbox
                  checked="true"
                  name="check-button"
                  inline
                  :value="device._id"
                  v-model="selected_devices"
                >
                  <h4>
                    <img
                      :src="device.info.image"
                      :style="
                        `width: 40px;
                        height: 40px;
                        object-fit: cover;
                        border-radius: 10px; ` +
                        (selected_devices.includes(device._id)
                          ? 'border: 4px solid green;'
                          : 'border: 4px solid red')
                      "
                    />
                    {{ device.title }}
                    <br />
                    <small
                      >{{ device.phone }}
                      <span class="text-muted">{{
                        device.info.bio
                      }}</span></small
                    >
                  </h4>
                </b-form-checkbox>
              </div>
            </div></template
          >
          <div class="col-12">
            <div class="row">
              <div class="col-12">إرسال لـــ</div>
              <div class="col-12 col-lg-6 g">
                <button
                  class="btn btn-block btn-lg btn-secondary btngreen"
                  @click="send_type = 1"
                >
                  <i class="fa fa-users"></i>
                  ارسال للأرقام المحفوظة
                </button>
              </div>
              <div class="col-12 col-lg-6 g">
                <button
                  class="btn btn-block btn-lg btn-secondary btngreen"
                  @click="send_type = 2"
                >
                  <i class="fa fa-user-times"></i>
                  ارسال لأرقام غير مسجلة
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 g" v-if="send_type == 1">
            <div class="card card-body border">
              <p>اختر المجموعات المراد الارسال لها</p>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <b-form-checkbox
                    name="check-button"
                    inline
                    :value="''"
                    v-model="selected_groups"
                  >
                    بدون مجموعة ({{ countOfNumbers("") }})
                  </b-form-checkbox>
                  <br />
                  <br />
                  <div
                    class="card card-body border"
                    style="height: 300px; overflow-y: scroll"
                    v-if="selected_groups.includes('')"
                  >
                    <div class="row">
                      <div class="col-12 col-lg-6">
                        <button
                          class="btn btn-sm btn-primary btn-block"
                          @click="selectAllInGroup('')"
                        >
                          <i class="fa fa-check-square"></i>
                          تحديد الكل
                        </button>
                      </div>
                      <div class="col-12 col-lg-6">
                        <button
                          class="btn btn-sm btn-secondary btn-block"
                          @click="unSelectAllInGroup('')"
                        >
                          <i class="fa fa-check-square-o"></i>
                          الغاء تحديد الكل
                        </button>
                      </div>
                    </div>
                    <br />
                    <input type="text" placeholder="ابحث هنا..." v-model="search">
                    <template v-for="(contact, index) in contacts">
                        <b-form-checkbox
                        v-if="contact.group_id == '' && (search && contact.phone.includes(search) || search && contact.name.includes(search) || search == '')"
                        :key="index"
                        name="check-button"
                        inline
                        :value="contact.phone"
                        v-model="selected_numbers"
                      >
                      <span style="white-space: nowrap !important;">
                      {{contact.phone}}
                        <small>{{ contact.name }}</small>
                      </span>
                      </b-form-checkbox>
                    </template>
                  </div>
                </div>
                <template v-for="group in groups">
                  <div class="col-12 col-lg-6" :key="group._id">
                    <b-form-checkbox
                      checked="true"
                      name="check-button"
                      inline
                      :value="group._id"
                      v-model="selected_groups"
                    >
                      {{ group.title }} ({{ countOfNumbers(group._id) }})
                    </b-form-checkbox>
                    <br />
                    <br />
                    <div
                      class="card card-body border"
                      style="height: 300px; overflow-y: scroll"
                      v-if="selected_groups.includes(group._id)"
                    >
                      <div class="row">
                        <div class="col-12 col-lg-6">
                          <button
                            class="btn btn-sm btn-primary btn-block"
                            @click="selectAllInGroup(group._id)"
                          >
                            <i class="fa fa-check-square"></i>
                            تحديد الكل
                          </button>
                        </div>
                        <div class="col-12 col-lg-6">
                          <button
                            class="btn btn-sm btn-secondary btn-block"
                            @click="unSelectAllInGroup(group._id)"
                          >
                            <i class="fa fa-check-square-o"></i>
                            الغاء تحديد الكل
                          </button>
                        </div>
                      </div>
                      <br />
                    <input type="text" placeholder="ابحث هنا..." v-model="group.search">
                      <template v-for="(contact, index) in contacts">
                        <b-form-checkbox
                          v-if="contact.group_id == group._id && (group.search && contact.phone.includes(group.search) || group.search && contact.name.includes(group.search) || !group.search )"
                          :key="index"
                          name="check-button"
                          inline
                          :value="contact.phone"
                          v-model="selected_numbers"
                        >

                      <span style="white-space: nowrap !important;">
                      {{contact.phone}}
                        <small>{{ contact.name }}</small>
                      </span>
                        </b-form-checkbox>
                      </template>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="col-12 g" v-if="send_type == 2">
            <div class="card card-body border">
              <div class="form-group">
                <h5 for="">اكتب الأرقام المراد الارسال لها</h5>
                <textarea
                  class="form-control"
                  rows="6"
                  v-model="numbers_text"
                  @change="changeNumbersText()"
                  placeholder="اكتب كل رقم في سطر، مثل:
9665066666666
9665077777777
9665088888888"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-12 col-lg-6 g">
            <div class="form-group">
              <label for="">متحوى الرسالة</label>
              <textarea
                class="form-control"
                v-model="message" id="msg"
                rows="3"
                placeholder="اكتب محتوى الرسالة هنا..."
              ></textarea>
            </div>
            <div class="form-check">
              <label class="form-check-label">
                <input
                  type="checkbox"
                  id="withname"
                  class="form-check-input"
                  v-model="withname"
                  :value="true"
                />
                <i class="fa fa-user"></i>
                تضمين الاسم في الرسالة
              </label>
            </div>
            <div v-if="withname">
              <div class="card card-body">
                قم بكتابة {name} في الرسالة وسيتم استبدالها بالاسم عند الارسال.
                <br /><br />
                مثال: <q>مرحباً يا {name} كيف حالك اليوم؟</q>
                <br />
                (يجب ان يكون الرقم مسجل في حسابك لوضع الاسم الخاص به في الرسالة)
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6 g">
            <div class="col-12 g">
              <div class="form-check">
                <label class="form-check-label">
                  <input type="checkbox" class="form-check-input" v-model="schedule_bool" :value="true">
                  جدولة الرسائل
                </label>
              </div>
              <div class="col-12" v-if="schedule_bool">
                <div class="form-group">
                  <label for="">اختر التاريخ والوقت</label>
                  <input type="datetime-local"
                    class="form-control" v-model="schedule">
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="">ارفاق صورة او ملف (اختياري)</label>
              <input
                type="file"
                id="ingredient_file"
                class="form-control"
                accept=".png,.jpeg,.jpg,.pdf,.doc,.docx,.xls,.xlsx"
              />
              <small>
                - بحد اقى 10 ميجا بايت للملف
                <br />
                - عند ارفاق ملفات غير الصور، فلن تظهر الرسالة
              </small>
            </div>
            <div id="progress-wrp">
              <div class="progress-bar"></div>
              <div class="status">0%</div>
            </div>
            <button class="btn btn-block btn-sm btn-secondary" v-if="file" @click="appendFile()">
              <i class="fa fa-link"></i>
              وضع الملف كرابط في الرسالة
              <br><br>
              (بدلاً من ارساله كملف)
            </button>
          </div>
        </div>
        <div class="col-12 text-center">
          <button class="btn btn-relief-primary" @click="send()">
            إرسال الآن <i class="fa fa-paper-plane"></i>
          </button>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BOverlay, BModal, VBModal, BFormCheckbox } from "bootstrap-vue";
var QRCode = require("qrcode");
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BOverlay,
    BModal,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  data() {
    return {
      search: "",
      loading: true,
      selected_devices: [],
      selected_groups: [],
      message: null,
      withname: false,
      schedule: null,
      devices: [],
      schedule_bool: false,
      file: "",
      selected_numbers: [],
      fullcontacts: [],
      numbers_text: "",
      groups: [],
      contacts: [],
      send_type: 0,
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  created() {
    var g = this;
    g.getDevices();
    $.post(api + "/user/contacts/groups/list", {
      jwt: this.user.jwt,
    })
      .then(function (r) {
        g.groups = r.response;
      })
      .catch(function () {
        g.loading = false;
        g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدثت مشكلة في الاتصال",
            icon: "EditIcon",
            variant: "danger",
          },
        });
      });
    $.post(api + "/user/contacts/list", {
      jwt: this.user.jwt,
      q: g.q,
      group_id: "all",
    })
      .then(function (r) {
        g.fullcontacts = r.response;
        g.contacts = r.response.map(function(x){
          if(!x.name){
            x.name = ""
          }
          if(!x.phone){
            x.phone = ""
          }
          return x
        });
      })
      .catch(function () {
        g.loading = false;
        g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدثت مشكلة في الاتصال",
            icon: "EditIcon",
            variant: "danger",
          },
        });
      });
    var Upload = function (file) {
      this.file = file;
    };
    Upload.prototype.getType = function () {
      return this.file.type;
    };
    Upload.prototype.getSize = function () {
      return this.file.size;
    };
    Upload.prototype.getName = function () {
      return this.file.name;
    };
    Upload.prototype.doUpload = function () {
      var that = this;
      var formData = new FormData();

      // add assoc key values, this will be posts values
      formData.append("file", this.file, this.getName());
      formData.append("jwt", g.user.jwt);
      $.ajax({
        type: "POST",
        url: api + "/user/auth/upload",
        xhr: function () {
          var myXhr = $.ajaxSettings.xhr();
          if (myXhr.upload) {
            myXhr.upload.addEventListener(
              "progress",
              that.progressHandling,
              false
            );
          }
          return myXhr;
        },
        success: function (data) {
          $("#progress-wrp").fadeOut("slow");
          g.file = data;
        },
        error: function (error) {
          // handle error
          alert("حدث خطأ");
        },
        async: true,
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        timeout: 60000,
      });
    };

    Upload.prototype.progressHandling = function (event) {
      var percent = 0;
      var position = event.loaded || event.position;
      var total = event.total;
      var progress_bar_id = "#progress-wrp";
      if (event.lengthComputable) {
        percent = Math.ceil((position / total) * 100);
      }
      // update progressbars classes so it fits your code
      $(progress_bar_id + " .progress-bar").css("width", +percent + "%");
      $(progress_bar_id + " .status").text(percent + "%");
    };
    setTimeout(() => {
      $("#ingredient_file").on("change", function (e) {
        $("#progress-wrp").fadeIn("slow");
        var file = $(this)[0].files[0];
        var upload = new Upload(file);

        // maby check size or type here with upload.getSize() and upload.getType()

        // execute upload
        upload.doUpload();
      });
    }, 1000);
  },
  methods: {
    selectAllInGroup(id) {
      var g = this;
      g.contacts.forEach(function (contact) {
        if (
          contact.group_id == id &&
          !g.selected_numbers.includes(contact.phone)
        ) {
          g.selected_numbers.push(contact.phone);
        }
      });
    },
    unSelectAllInGroup(id) {
      var g = this;
      g.contacts.forEach(function (contact) {
        if (
          contact.group_id == id &&
          g.selected_numbers.includes(contact.phone)
        ) {
          var index = g.selected_numbers.indexOf(contact.phone);
          if (index > -1) {
            g.selected_numbers.splice(index, 1);
          }
        }
      });
    },
    appendFile(){
      var g = this;
      document.getElementById('ingredient_file').value= null;
      g.message = g.message + '\nرابط الملف:\n ' + encodeURI(g.file)
      g.file = null
      var oneDiv = $("#msg");
      bottom = oneDiv.prop('scrollHeight') - oneDiv.height()
    },
    changeNumbersText() {
      var g = this;
      g.selected_numbers = [];
      g.numbers_text.split("\n").forEach((element) => {
        if (element?.trim() != "" && element) {
          if (!g.selected_numbers.includes(element)) {
            g.selected_numbers.push(element);
          }
        }
      });
    },
    getDevices() {
      var g = this;
      $.post(api + "/user/devices/list", {
        jwt: this.user.jwt,
      })
        .then(function (r) {
          g.devices = r.response;
          g.loading = false;
          g.devices.forEach((element) => {
            g.selected_devices.push(element._id);
          });
        })
        .catch(function () {
          g.loading = false;
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدثت مشكلة في الاتصال",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        });
    },
    countOfNumbers(id) {
      var count = 0;
      this.contacts.forEach(function (contact) {
        if (contact.group_id == id?.toString()) {
          count = count + 1;
        }
      });
      return count;
    },
    send() {
      var g = this;
      var contacts = g.selected_numbers;
      if (contacts.length == 0) {
        g.$toast({
          component: ToastificationContent,
          props: {
            title: "لا يوجد ارقام للإرسال لها",
            icon: "EditIcon",
            variant: "danger",
          },
        });
      } else {
        if (g.message?.trim() == "" || !g.message) {
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "برجاء كتابة الرسالة",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        } else {
          g.loading = true;
          if ($("#withname").is(":checked")) {
            var con = [];
            g.fullcontacts.forEach(function (a) {
              if (contacts.includes(a.phone)) {
                con.push(a);
              }
            });
            contacts = con;
          }
          $.post(api + "/user/messages/send", {
            jwt: g.user.jwt,
            withname: $("#withname").is(":checked"),
            devices: g.selected_devices,
            numbers: JSON.stringify(contacts),
            message: g.message,
            file: g.file ?? "",
            schedule: g.schedule
          })
            .then(function (r) {
              g.loading = false;
              if (r.status == 100) {
                g.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "تم بدء عملية الإرسال بنجاح",
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
                g.$router.push("/archive");
              } else {
                g.$toast({
                  component: ToastificationContent,
                  props: {
                    title: r.response,
                    icon: "EditIcon",
                    variant: "danger",
                  },
                });
              }
            })
            .catch(function () {
              g.loading = false;
              g.$toast({
                component: ToastificationContent,
                props: {
                  title: "حدث مشكلة في الاتصال",
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            });
        }
      }
    },
  },
};
</script>

<style scoped>
#progress-wrp {
  border: 1px solid #0099cc;
  padding: 1px;
  position: relative;
  height: 30px;
  border-radius: 3px;
  margin: 10px;
  text-align: left;
  background: #fff;
  box-shadow: inset 1px 3px 6px rgba(0, 0, 0, 0.12);
  display: none;
}

#progress-wrp .progress-bar {
  height: 100%;
  border-radius: 3px;
  background-color: greenyellow;
  width: 0;
  box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.11);
}

#progress-wrp .status {
  top: 3px;
  left: 50%;
  position: absolute;
  display: inline-block;
  color: #000000;
}
</style>